import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .super-app-theme--cell {
    text-align: center !important;
    align-items: center !important;
  }
  .actButtonsCredito {
    margin-top: 31px;
    display: flex;
    flex-direction: row;

    .btn-outline-danger {
      border-color: #d4a9a9;
      color: #d94848;
    }
    .btn-outline-danger:hover {
      color: #fff;
    }
    button:nth-child(2) {
      border-color: #a9d4c0;
      color: #72ab90;
      margin-left: 10px;

      &:hover {
        background-color: #72ab90;
        color: #fff;
      }
    }
    button:focus {
      outline: 0;
    }
  }
  .inputButton {
    float: left;
    margin-left: -1px;
    height: 39px;
    line-height: 39px;
    margin-top: -48px;
    align-items: center;
    justify-content: center;
    border-radius: 3px 0px 0px 3px;
    background-color: ${({ theme: { colors } }) => colors.nav}!important;
  }
  .flags {
    display: flex;
    flex-wrap: wrap;
    @media (min-width: 400px) {
      .flag:nth-child(2) {
        margin-left: 30px;
      }
    }
  }

  /* Capa */
  .xml-data {
    /* background: #ebfffe; */
    padding-bottom: 15px;
    border-radius: 10px;

    div {
      /* background: #ebfffe !important; */
      border-color: #ebfffe !important;
      /* color: #000 !important; */

      label {
        /* color: #2e2e2e !important; */
      }

      input {
        /* background: #defcfb; */

        &:disabled {
          -webkit-text-fill-color: #878787;
        }
      }
    }
  }
  /* Parâmetros */
  .accordion-row {
    svg {
      font-size: 23px;
      cursor: pointer;
      transition: 0.2s;
      color: #ca68d4;

      &:hover {
        color: #bd00cf;
      }
    }
  }

  .params-data {
    /* background: #f7f2ff; */
    border-radius: 10px;
    padding-bottom: 15px;
    /* .react-select__control {
      background: #e9dbff !important;
    } */
    .span-info {
      display: flex !important;

      span {
        display: flex !important;
        width: 100%;
        height: 40px;
        margin: auto auto 0 auto;
        color: #6b778c !important;
        justify-content: center;
        align-items: center;
      }
    }
    .btn-validar {
      display: flex;
      align-items: end;
      justify-content: end;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        background: #50bf75;
        color: #fff;
        border: none;
        box-shadow: none !important;
      }
      button:first-of-type {
        min-width: 138px;
        border-radius: 3px 0px 0px 3px;
        &:hover {
          background: #3b8f57;
        }
      }
      button:nth-child(2) {
        border-radius: 0px 3px 3px 0px;
        &:hover {
          background: #3b8f57;
        }
      }
      .dropdown-menu {
        background: #50bf75;
        border-radius: 3px;
        a {
          font-family: inherit;
          font-size: 1rem;
          color: #fff;
          &:hover {
            background: #3b8f57;
          }
        }
      }
    }
    .log-visialization {
      margin-top: 20px;
      textarea {
        padding: 10px;
        border-radius: 2px;
      }
    }
  }
  /* Comparação */
  .capa-tabs {
    button {
      padding: 0 10px;
      height: 24px;
      background: #c6c6c6;
      border: none;

      cursor: pointer;
      & + button {
        margin-left: 2px;
      }
    }
    .active {
      background: ${(props) => props.theme.colors.nav};
      color: #fff;
    }
  }
  .data-comparison {
    /* overflow: hidden; */
    .tab-pane.active {
      animation: slide-left 0.3s ease-out;
    }
    @keyframes slide-left {
      0% {
        opacity: 0;
        transform: translateX(3%);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    .nav-tabs {
      display: none;
      visibility: hidden;
    }
  }

  .menu {
    position: absolute;
    background-color: #292a2d;
    color: #d2ddd9;
    border-radius: 10px;
    padding: 10px 0 10px 0;
    min-width: 150px;
    height: auto;
    /* margin: 0; */

    list-style: none;
    box-shadow: 0 0 20px 0 #ccc;

    opacity: 1;
    transition: opacity 0.5s linear;
    z-index: 999999;

    li {
      height: 30px;
      button {
        padding: 0px 20px 0px 20px;
        height: 28px;
        text-align: left;
        width: 100%;
        font-size: 0.77rem;
        color: #ededed;
        background: transparent;
        border: none;
        font-family: 'Segoe UI', sans-serif;

        &:hover {
          background: #3f4042;
        }
      }
      svg {
        color: #9aa0a6;
      }
    }
  }

  .table-exibition {
    .itensTable {
      svg {
        font-size: 24px;
      }
    }

    .td-style {
      white-space: nowrap;
    }
    .editado {
      color: #48778c !important;
      background: #dcfcfb !important;
    }

    .table tbody {
      border: 1px solid #dee2e6 !important;
    }
    .table tbody tr td {
      color: #757575;
      width: 16.6%;
      border-left: 1px solid #dee2e6;
    }
    .table tbody tr td input {
      width: 100%;
      height: 100%;
      border: none;
      box-sizing: border-box;
      &:disabled {
        border: none !important;
        background-color: transparent !important;
      }
    }
    .table thead tr th {
      width: 16.6%;
      padding-left: 0px;
      border: none;
      font-size: 0.8571428571428571em;
      font-style: inherit;
      color: #8850bf;
      font-weight: 600 !important;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }
  }

  .capa-error {
    background: #ef5350 !important;
  }
  .capa-warning {
    background: #ffff81 !important;
  }
`;

export const StyledForm = styled.form`
  padding: 20px;
  border-radius: 5px;
  .inlineField {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;

  .iconStyle {
    font-size: 12px;
    margin-top: -3px;
    margin-right: 2px;
  }
`;

export const ButtonRow = styled.button`
  flex: 1;
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
  }
`;

export const ActionModal2 = styled(Modal)`
  .modal-header {
    background: ${(props) => props.theme.colors.nav};
    color: #ffffff;
  }
  .modal-body {
    button {
      padding: 0 10px;
      height: 24px;
      background: #c6c6c6;
      border: none;

      cursor: pointer;
      & + button {
        margin-left: 2px;
      }
    }
    .aba-ativa {
      background: ${(props) => props.theme.colors.nav} !important;
      color: #fff;
    }
    .modal-acoes {
      height: 60px;

      cursor: pointer;

      & + .modal-acoes {
        border-top: 1px solid #ededed;
      }
      h5 {
        font-size: 16px;
        color: #474747;
      }
      svg {
        color: ${(props) => props.theme.colors.nav} !important;
      }
    }
  }
  .modal-footer {
    button {
      background: ${(props) => props.theme.colors.nav};
      border-color: ${(props) => props.theme.colors.nav};

      &:focus:not(.focus-visible) {
        outline: none !important;
        outline: 0px !important;
        -webkit-appearance: none;
        appearance: none;
        box-shadow: none !important;
      }
      &:focus-visible {
        outline: 2px solid ${(props) => props.theme.colors.nav} !important;
      }
    }
  }
`;

export const SectionParams = styled.section`
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.125rem;

  .toggle-content-considerar-impostos,
  .toggle-content {
    display: flex !important;
    align-items: center;
    gap: 0.9375rem;
  }
`;

export const ButtonForm = styled.button`
  padding: 3px 12px;
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 160px;
  background-color: #28a745;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 16px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  margin-right: 8px;
  margin-left: 8px;

  animation: all 0.25s ease-in;

  &.download {
    background: #007bff;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &.disabled:hover {
    opacity: 1;
  }

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 992px) {
    width: 100% !important;
  }
`;
