/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const uppercaseObjectValues = (
  obj: Record<string, unknown>,
): Record<string, unknown> => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key,
      typeof value === 'string' ? (value as string).toUpperCase() : value,
    ]),
  );
};

export const uppercaseValues = (objeto: any): any => {
  const newObjeto = { ...objeto };

  newObjeto.fornecedor = {
    ...newObjeto.fornecedor,
    enderEmit: uppercaseObjectValues(newObjeto.fornecedor.enderEmit),
  };

  newObjeto.loja = {
    ...newObjeto.loja,
    enderDest: uppercaseObjectValues(newObjeto.loja.enderDest),
  };

  return newObjeto;
};
